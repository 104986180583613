import { makeAutoObservable, reaction, autorun, computed, comparer, runInAction, action, toJS } from 'mobx'

import makeQuery, { fromApi } from 'api/makeQuery'
import Providers from 'singletons/Providers'
import Pusher from 'singletons/Pusher'
import GlobalEvents from 'singletons/GlobalEvents'
import Auth from 'models/Auth'
import safeJsonParse from 'util/safeJsonParse'
import pick from 'util/pick'
import MemberExtendedData from 'singletons/MemberExtendedData'
import MemberPrivateData from 'singletons/MemberPrivateData'
import ChatMessageNotifier from 'singletons/ChatMessageNotifier'
import Mfa from 'singletons/Mfa'
import Modals from 'singletons/Modals'
import { isDosespotNotConfirmed } from 'util/login'
import { setUser } from 'util/apm'

const LS_NAME = 'TIMELY_USER'
const ALLOWED_LOCALSTORAGE_KEYS = ['providerId']

const getPusherState = (state) => (state === '*' ? 'all' : state)

class User {
  currentVideo = null
  auth = new Auth()
  mfa = new Mfa(this)
  mfaRequired = false
  providerId = ''
  lastSuccessfulPassword = ''
  passwordExpired = false
  temporaryEmail = ''
  flags = null

  constructor() {
    Object.assign(this, safeJsonParse(sessionStorage.getItem(LS_NAME), ALLOWED_LOCALSTORAGE_KEYS))

    makeAutoObservable(this, {
      needsLogin: false,
      temporaryEmail: false,
      setTemporaryEmail: false,
      resetTemporaryEmail: false,
      pusherPendingVisitIds: computed({ equals: comparer.shallow }),
      initializeProvider: action,
      isScDocumentationUpdatesEnabled: computed,
      isHidePrescriptionsFeatureFlagEnabled: computed,
      isMemberIdFilterFlagEnabled: computed,
      isMedicationSafetyPhase2FlagEnabled: computed,
    })

    reaction(
      () => this.providerId && this.auth.token,
      (token) => Pusher.start(token),
      { fireImmediately: true },
    )

    reaction(
      () => JSON.stringify(pick(this, ALLOWED_LOCALSTORAGE_KEYS)),
      (lsValue) => sessionStorage.setItem(LS_NAME, lsValue),
      { delay: 500 },
    )

    reaction(
      () => this.providerId,
      (providerId, prevProviderId) => {
        if (prevProviderId) {
          Pusher.unsubscribeChannel(`private-providers-${prevProviderId}`)
        }
        if (providerId) {
          Pusher.subscribeChannel(`private-providers-${providerId}`)
        }
        setUser(providerId ? { id: providerId } : null)
      },
      { fireImmediately: true, delay: 500 },
    )

    reaction(
      () => this.pusherPendingVisitIds,
      (ids, prevIds) => {
        if (prevIds) {
          for (const id of prevIds) {
            Pusher.unsubscribeChannel(`private-pending-visits-${id}`)
          }
        }
        if (ids) {
          for (const id of ids) {
            Pusher.subscribeChannel(`private-pending-visits-${id}`)
          }
        }
      },
      { fireImmediately: true, delay: 500 },
    )

    reaction(
      () => this.provider?.licenses.slice(),
      (licenses, prevLicenses) => {
        if (prevLicenses && prevLicenses.length) {
          for (const { type, state } of prevLicenses) {
            Pusher.unsubscribeChannel(`private-pending-visits-${type}-${getPusherState(state)}`)
          }
        }
        if (licenses && licenses.length) {
          // These channels are only useful for viewing visits in the queue
          // as such, we only need to subscribe to the 'therapy' variety of this channel
          // when the provider has an "ALL"/"*" license state, signifying they're a TalkNow provider
          const wildcardOnDemandLicenses = licenses.filter(({ type }) => ['therapy', 'success_coaching'].includes(type))
          if (wildcardOnDemandLicenses.some(({ state }) => state === '*')) {
            for (const { type, state } of wildcardOnDemandLicenses) {
              Pusher.subscribeChannel(`private-pending-visits-${type}-${getPusherState(state)}`)
            }
          }
          const otherLicenses = licenses.filter(({ type }) => type !== 'therapy')
          for (const { type, state } of otherLicenses) {
            Pusher.subscribeChannel(`private-pending-visits-${type}-${getPusherState(state)}`)
          }
        }
      },
      { fireImmediately: true, delay: 500, equals: comparer.structural },
    )

    autorun(() => {
      if (this.provider) {
        // Pendo
        if (window.pendo) {
          const {
            providerId,
            email,
            firstName,
            lastName,
            type,
            uniqueLicenseTypes,
            uniqueLicenseStates,
            profile,
            modalities,
            priority,
            paymentType,
          } = this.provider

          const { pronouns, raceEthnicity, genderIdentity, languages } = profile || {}

          const identifyInformation = {
            visitor: {
              id: providerId,
              email,
              fullName: `${firstName} ${lastName}`,
              providerType: type,
              licenseTypes: uniqueLicenseTypes,
              licenseState: uniqueLicenseStates,
              pronouns,
              raceEthnicity,
              genderIdentity,
              languages: toJS(languages),
              modality: toJS(modalities),
              notificationPriority: priority,
            },
            account: {
              id: paymentType,
              environment: process.env.REACT_APP_ENVIRONMENT,
            },
          }
          window.pendo.initialize(identifyInformation)
        }
      }
    })

    Pusher.listen((e) => {
      const isProviderVisitsV3Active = this.getFeatureFlagByName('performanceProviderVisitsFilterV3')

      switch (e.event) {
        case 'complete-visit':
          this.provider?.visitQueue.removeByPendingVisitId(e.meta.pending_visit_id)
          MemberExtendedData.clear()
          MemberPrivateData.clear()
          if (isProviderVisitsV3Active) {
            this.provider?.visitQueue.refetchVisit(e.meta.pending_visit_id)
          } else {
            this.provider?.refetchCollections()
          }
          break
        case 'auto-assign-visit':
          this.provider?.fetchAssignedVisit(e.meta.pending_visit_id)
          break
        case 'claim-visit':
          if (e.provider_id !== this.providerId) {
            /*
                Need to delete manually,
                because refetchCollections will not return it in case of different providerId
              */
            this.provider?.visitQueue.removeByPendingVisitId(e.meta.pending_visit_id)
          } else if (isProviderVisitsV3Active) {
            this.provider?.visitQueue.refetchVisit(e.meta.pending_visit_id)
          } else {
            this.provider?.refetchCollections()
          }
          break
        case 'unclaim-visit':
          if (e.provider_id === this.providerId) {
            this.provider?.visitQueue.removeByPendingVisitId(e.meta.pending_visit_id)
          } else if (isProviderVisitsV3Active) {
            // TODO - use this to update the one visit
            this.provider?.visitQueue.refetchVisit(e.meta.pending_visit_id)
          } else {
            this.provider?.refetchCollections()
          }
          break
        case 'visit-sent-back-to-queue':
          this.provider?.visitQueue.getById(e.meta.pending_visit_id)?.getExtendedRecord()
          if (isProviderVisitsV3Active) {
            this.provider?.visitQueue.refetchVisit(e.meta.pending_visit_id)
          } else {
            this.provider?.refetchCollections()
          }
          break
        case 'cancel-visit':
          this.provider?.visitQueue.getById(e.meta.pending_visit_id)?.getExtendedRecord()
          if (isProviderVisitsV3Active) {
            this.provider?.visitQueue.refetchVisit(e.meta.pending_visit_id)
          } else {
            this.provider?.refetchCollections()
          }
          break
        case 'create-pending-visit':
        case 'update-pending-visit':
        case 'create-external-visit':
        case 'void-external-visit':
        case 'start-visit':
          if (isProviderVisitsV3Active) {
            this.provider?.visitQueue.refetchVisit(e.meta.pending_visit_id)
          } else {
            this.provider?.refetchCollections()
          }
          break
        case 'create-chat-message': {
          /*
              Should be subscribed to:
              pending_visits/:pending_visit_id
              to receive chat messages
          */
          const pendingVisit = this.provider?.visitQueue.getById(e.meta.pending_visit_id)
          if (pendingVisit?.providerId === this.providerId) {
            ChatMessageNotifier.notify(e.meta.pending_visit_id)
            pendingVisit?.chat.addMessage(fromApi(e.meta.chat_message))
          }
          break
        }
        case 'update-chat-message':
          this.provider?.visitQueue
            .getById(e.meta.pending_visit_id)
            ?.chat.viewMessages(fromApi(e.meta.updated_chat_messages))
          break
        case 'rx-error':
          this.provider?.fetchTransmissionErrors()
          break
        case 'rx-change':
          this.provider?.rxChangesList.refetch()
          break
        case 'rx-verified':
          if (e.meta && this.provider) {
            const isSoughtVisit = (visit) => visit.visitId === e.meta.visit_id
            const visit =
              this.provider.visitQueue.list.find(isSoughtVisit) || this.provider.pastVisits.list.find(isSoughtVisit)

            if (visit) {
              visit.rxs.list.find((rx) => rx.prescriptionId === e.meta.prescription_id)?.refetch()
            }
          }
          break
        case 'visit-photo-uploaded': {
          const pendingVisit = this.provider?.visitQueue.getById(e.meta.pending_visit_id)
          if (pendingVisit?.providerId === this.providerId) {
            this.provider?.visitQueue.getById(e.meta.pending_visit_id)?.images.refetch()
          }
          break
        }
        case 'pusher:subscription_error':
          console.warn('Subscription error', e)
          break
      }
    })
  }

  setFeatureFlags(flags) {
    this.flags = flags
  }

  getFeatureFlagByName(flagName) {
    return this.flags?.[flagName]
  }

  get pusherPendingVisitIds() {
    return this.provider?.visitQueue.pusherPendingVisits?.map(({ pendingVisitId }) => pendingVisitId) ?? []
  }

  get needsLogin() {
    return !this.providerId || this.auth.shouldRefetchTokenManually
  }

  get provider() {
    return this.providerId ? Providers.get(this.providerId) : null
  }

  get isScDocumentationUpdatesEnabled() {
    return !!this.getFeatureFlagByName('pwScDocumentationUpdates')
  }

  get isHidePrescriptionsFeatureFlagEnabled() {
    return !!this.getFeatureFlagByName('pwHidePrescriptions')
  }

  get isV2ImageEndpointsEnabled() {
    return !!this.getFeatureFlagByName('tcV2ImageEndpoints')
  }

  get isVirtualBackgroundEnabled() {
    return !!this.getFeatureFlagByName('pwVirtualBackground')
  }

  get isMemberIdFilterFlagEnabled() {
    return !!this.getFeatureFlagByName('pwMemberIdFilter')
  }

  get isMedicationSafetyPhase2FlagEnabled() {
    return !!this.getFeatureFlagByName('pwMedicationSafetyPhase2')
  }

  resetPassword(password, resetToken) {
    /*
      Returns:
      {
        unique_id: user.unique_id,
        email: user.email,
        verified: user.verified,
      }
    */
    return makeQuery('postResetPassword', {
      password,
      resetToken,
    })
  }

  async updateExpiredPassword(password) {
    if (!this.lastSuccessfulPassword) {
      throw new Error('Current password is unknown')
    }
    await this.updatePassword(password, this.lastSuccessfulPassword)
    this.setPasswordExpired(false)
    /*
      When password_expired: true from @postLogin, auth is returned but no provider info, calling @getMe here to avoid calling @postLogin twice.
      Which, when Mfa_required: true and password_expired: true, would force provider to verify via mfa twice.
    */
    const { provider, providerId, resources } = await makeQuery('getMe')
    this.initializeProvider(providerId, provider, resources)
  }

  async updatePassword(password, oldPassword) {
    const res = await makeQuery('putUpdatePassword', {
      password,
      oldPassword,
    })

    GlobalEvents.emit('updateUserPassword', {
      status: 'success',
    })

    return res
  }

  initializeProvider(providerId, provider, resources) {
    /* Normally both provider and providerId should exist. Adding checks for extra safety. */
    if (providerId) {
      this.providerId = providerId
      if (provider) {
        Providers.upsert(providerId, {
          ...provider,
          resources,
        })
      }
    }
  }

  setPasswordExpired(passwordExpired) {
    this.passwordExpired = !!passwordExpired
  }

  async login(email, password) {
    const data = await makeQuery('postLogin', {
      email,
      password,
      refreshMfaToken: this.mfa.getTokenForEmail(email),
    })

    if (isDosespotNotConfirmed(data)) {
      return data
    }

    runInAction(() => {
      const { auth, provider, providerId, passwordExpired, resources, mfaRequired, mfaChoices } = data
      this.setPasswordExpired(passwordExpired)
      this.lastSuccessfulPassword = password
      this.auth.initialize(auth)
      this.mfaRequired = !!mfaRequired
      if (mfaRequired) {
        this.mfa.initialize(mfaChoices, auth.token, email)
      } else {
        this.initializeProvider(providerId, provider, resources)
      }
    })

    return data
  }

  async processSsoLogin(auth) {
    this.auth.initialize(auth)
    const { provider, providerId, resources } = await makeQuery('getMe')
    this.initializeProvider(providerId, provider, resources)
  }

  async handleForgotPassword(email) {
    try {
      const res = await makeQuery('postForgotPassword', {
        userType: 'provider',
        email,
      })

      GlobalEvents.emit('forgotPassword', {
        status: 'success',
        email,
      })
      return res
    } catch (e) {
      GlobalEvents.emit('forgotPassword', {
        status: 'error',
        email,
      })
    }
  }

  resendVerificationEmail(email) {
    return makeQuery('postResendVerificationEmail', {
      email,
    })
  }

  fetchAuthChallenge(email) {
    return makeQuery('postAuthChallenge', {
      email,
    })
  }

  async getVisitByPendingVisitId(pendingVisitId) {
    const visitRecord = await makeQuery('getPendingVisit', {
      providerId: this.providerId,
      visitId: pendingVisitId,
      include: {
        member: 'group_type',
      },
    })
    const { visit, ...restPendingVisit } = visitRecord

    if (!visit) {
      switch (restPendingVisit.status) {
        case 'in-progress':
        case 'in-review':
        case 'completed':
          console.log({ visitRecord })
          throw new Error('Visit must exist, but it is absent')
      }
    }

    /* merge order is important */
    return {
      ...restPendingVisit,
      ...visit,
    }
  }

  setTemporaryEmail(email) {
    this.temporaryEmail = email
  }

  resetTemporaryEmail() {
    this.setTemporaryEmail('')
  }

  setCurrentVideo(videoStore) {
    this.currentVideo = videoStore
  }

  logout() {
    this.providerId = ''
    this.auth.reset()
    this.mfa.reset(false)
    Providers.clear()
    MemberExtendedData.clear()
    MemberPrivateData.clear()
    Modals.closeAll()
  }
}

export default new User()
